@font-face {
    font-family: 'DM Mono';
    src: url('DMMono-LightItalic.eot');
    src: local('DM Mono Light Italic'), local('DMMono-LightItalic'),
        url('DMMono-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('DMMono-LightItalic.woff2') format('woff2'),
        url('DMMono-LightItalic.woff') format('woff'),
        url('DMMono-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'DM Mono';
    src: url('DMMono-Light.eot');
    src: local('DM Mono Light'), local('DMMono-Light'),
        url('DMMono-Light.eot?#iefix') format('embedded-opentype'),
        url('DMMono-Light.woff2') format('woff2'),
        url('DMMono-Light.woff') format('woff'),
        url('DMMono-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DM Mono';
    src: url('DMMono-Italic.eot');
    src: local('DM Mono Italic'), local('DMMono-Italic'),
        url('DMMono-Italic.eot?#iefix') format('embedded-opentype'),
        url('DMMono-Italic.woff2') format('woff2'),
        url('DMMono-Italic.woff') format('woff'),
        url('DMMono-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DM Mono';
    src: url('DMMono-MediumItalic.eot');
    src: local('DM Mono Medium Italic'), local('DMMono-MediumItalic'),
        url('DMMono-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('DMMono-MediumItalic.woff2') format('woff2'),
        url('DMMono-MediumItalic.woff') format('woff'),
        url('DMMono-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'DM Mono';
    src: url('DMMono-Medium.eot');
    src: local('DM Mono Medium'), local('DMMono-Medium'),
        url('DMMono-Medium.eot?#iefix') format('embedded-opentype'),
        url('DMMono-Medium.woff2') format('woff2'),
        url('DMMono-Medium.woff') format('woff'),
        url('DMMono-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DM Mono';
    src: url('DMMono-Regular.eot');
    src: local('DM Mono Regular'), local('DMMono-Regular'),
        url('DMMono-Regular.eot?#iefix') format('embedded-opentype'),
        url('DMMono-Regular.woff2') format('woff2'),
        url('DMMono-Regular.woff') format('woff'),
        url('DMMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

