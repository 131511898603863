.tags-list{
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;

  li{
	a, p{
	  color: $base-300;
	  font-family: "DM Mono", sans-serif;
	  font-size: 0.75rem;
	  font-weight: normal;
	  line-height: normal;
	  border-radius: 3.3125rem;
	  border: 1px solid $base-300;
	  background: $bg-100;
	  transition: 0.5s all ease;
	  padding: 0.65rem 1.5rem;
	  text-align: center;

	  @include breakpoint(md){
		padding: 0.75rem 1.5rem;
	  }

	  &:hover{
		background-color: $accent-100;
	  }
	}
  }

  &.accent-color{
	li{
	  a, p{
		border-color: $accent-400;
		background-color: $accent-100;
		color: $accent-400;

		&:hover{
		  background-color: $accent-400;
		  color: $white;
		}
	  }
	}
  }
}