.testimonials{
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;

  &__swiper{
	overflow: initial;
  }

  &-card{
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	position: relative;
	transition: 0.5s all ease;

	&:hover{
	  &:after{
		transform: rotate(-3.898deg);
	  }
	}

	&:after{
	  content: '';
	  position: absolute;
	  top: 1px;
	  left: 1px;
	  bottom: 1px;
	  right: 1px;
	  background-color: $accent-400;
	  z-index: 1;
	  border-radius: 1rem;
	  transition: 0.5s all ease;
	}

	&__inner{
	  width: 100%;
	  display: flex;
	  flex-direction: column;
	  flex-grow: 1;
	  background-color: $bg-100;
	  border-radius: 1rem;
	  padding: 2.5rem 1rem 1rem 1rem;
	  color: $base-300;
	  min-height: 30.125rem;
	  position: relative;
	  z-index: 2;

	  @include breakpoint(xs){
		padding: 3.5rem 1.5rem 1.5rem 1.5rem;
	  }
	}

	&__description{
	  margin-bottom: 1rem;
	}

	&__user{
	  margin-top: auto;
	  display: grid;
	  grid-template-columns: auto 1fr;
	  gap: 0 1.5rem;
	  align-items: center;
	}

	&__photo{
	  width: 5rem;
	  height: 5rem;
	  border-radius: 50%;
	  overflow: hidden;
	  display: block;
	}

	&__name{
	  font-weight: 400;
	  color: $accent-400;
	}
  }

  .swiper__nav{
	@include breakpoint(xl){
	  display: none;
	}
  }
}