.consultation{
  padding: 6.81rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $bg-100;
  min-height: 100vh;
  position: relative;
  z-index: 1;

  @include breakpoint(xl){
	min-height: 34.125rem;
  }

  @include breakpoint(xxl){
	min-height: 67.5625rem;
  }

  &.our-credo{
	min-height: 100vh;

	.consultation__title{
	  max-width: 36rem;

	  @include breakpoint(xl){
		max-width: 54rem;
	  }

	  @include breakpoint(xxl){
		max-width: 70rem;
	  }
	}
  }

  &__container{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: $accent-400;
  }

  &__title{
	max-width: 30rem;

	@include breakpoint(xl){
	  max-width: 45rem;
	}

	& > div{
	  background: linear-gradient(
		to right,
		($accent-400) 50%,
		($accent-300) 50%);
	  background-size: 200% 100%;
	  background-position-x: 100%;
	  color: transparent;
	  background-clip: text;
	}
  }

  &__item{
	font-family: "DM Mono", sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: normal;
	line-height: normal;
	padding: 0.5rem 1.5rem;
	background-color: $bg-100;
	transform: rotate(4.129deg);
	border: 1px solid $accent-400;
	color: $accent-400;
	border-radius: 3.3125rem;
	margin-top: -0.5rem;

	@include breakpoint(md){
	  margin-left: 10rem;
	  margin-top: -0.25rem;
	}

	@include breakpoint(xl){
	  padding: 0.75rem 1.5rem;
	  margin-top: -0.75rem;

	}

	@include breakpoint(xxl){
	  font-size: 1rem;
	  margin-top: -1rem;
	}
  }

  &__list{
	max-width: 36rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.54rem;
	position: relative;
	z-index: 1;

	@include breakpoint(xl){
	  max-width: 54rem;
	  flex-direction: row;
	  justify-content: space-between;
	}

	@include breakpoint(xxl){
	  max-width: 70rem;
	}

	.consultation__item{
	  margin: 0;

	  &:nth-child(even){
		align-self: flex-end;
	  }
	}

	&--top{
	  margin-bottom: -0.3rem;

	  @include breakpoint(xl){
		margin-bottom: -0.75rem;
	  }

	  .consultation__item{
		&:first-child{
			transform: rotate(-8.828deg);
		}

		&:last-child{
		  transform: rotate(4.285deg);

		  @include breakpoint(xl){
			margin-right: 5rem;
		  }
		}
	  }
	}

	&--bottom{
	  margin-top: -0.5rem;

	  @include breakpoint(xl){
		margin-top: -1rem;
	  }

	  .consultation__item{

		&:first-child{
		  @include breakpoint(xl){
			margin-left: 10rem;
		  }
		}

		&:last-child{
		  transform: rotate(-4.961deg);
		}
	  }
	}
  }

  &__button{
	margin-top: 3.37rem;
  }

  &__go-to-section{
	margin-top: 5.72rem;
	display: flex;
	width: 2.5rem;
	height: 2.5rem;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	background-color: $accent-400;
	border-radius: 50%;
	transition: 0.5s all ease;

	@include breakpoint(lg){
	  margin-top: 2.14rem;
	}

	@include breakpoint(xxl){
	  margin-top: 3.53rem;
	  width: 3.5rem;
	  height: 3.5rem;
	}

	&:hover{
	  background-color: $accent-300;
	}

	svg{
	  width: 1.125rem;
	  height: 1.125rem;

	  @include breakpoint(xxl){
		width: 1.5rem;
	  	height: 1.5rem;
	  }
	}
  }
}