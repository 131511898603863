.js_nav_open {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: rgba(34, 34, 34, 0.50);
  z-index: 999;
  transition: left 0.5s ease;
}

.hidden_body {
  overflow: hidden;
}

.header{
  padding: 1.25rem 0;

  @include breakpoint(xl){
	padding: 2.25rem 0;
  }

  &__inner{
	display: flex;
	align-items: center;
	justify-content: space-between;
  }

  &__logo{
	width: 5.5rem;
	height: 1.25rem;
	flex-shrink: 0;
	display: block;

	@include breakpoint(sm){
	  width: 7rem;
	  height: 1.625rem;
	}

	img{
	  width: 100%;
	  height: 100%;
	  display: block;
	}
  }

  &__nav{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    transition: left .3s ease;
    left: -100%;
    top: 0;
    max-width: 320px;
    background: $white;
    overflow-y: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    padding: 1.25rem 1.25rem 1.87rem;
	display: flex;
    flex-direction: column;
	border-radius: 0 1rem 1rem 0;

    @include breakpoint(xl){
	  position: relative;
      left: auto;
	  top: auto;
	  overflow: initial;
	  flex-direction: row;
	  justify-content: space-between;
	  align-items: center;
	  background: transparent;
	  max-width: 68rem;
	  flex-grow: 1;
	  width: auto;
	  height: auto;
	  padding: 0;
	  border-radius: 0;
    }

	@include breakpoint(xxl){
	  max-width: 80rem;
	}

    &.active {
      left: 0;
    }
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }

	&_close{
	  position: absolute;
	  top:2rem;
	  right: 1.25rem;
	  width: 1.5rem;
	  height: 1.5rem;
	  cursor: pointer;

	  @include breakpoint(xl){
		display: none;
	  }
	}

	&_list{
	  margin-bottom: 2.5rem;
	  display: flex;
	  flex-direction: column;
	  align-items: flex-start;
	  gap: 1rem;

	  @include breakpoint(xl){
		order: 0;
		flex-direction: row;
		padding: 0.44rem 1rem;
		border-radius: 0.5rem;
		background-color: $bg-100;
		margin: 0 1.5rem 0 0;
	  }

	  li{
		@include breakpoint(xl){
		  padding-right: 1rem;
		  position: relative;

		  &:last-child{
			padding-right: 0;

			&:after{
			  display: none;
			}
		  }

		  &:after{
			content: '';
			position: absolute;
			background: #CDCDCD;
			width: 1px;
			height: 1.19rem;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		  }
		}

		a{
		  font-size: 0.875rem;

		  @include breakpoint(xl){
			//font-size: 1rem;
			border-radius: 3.125rem;
			background-color: transparent;
			display: flex;
			padding: 0.5rem 1rem;
			transition: 0.5s all ease;

			&:hover{
			  background-color: $white;
			}
		  }
		}
	  }

	}

	&_lang{
	  margin-bottom: 4.38rem;
	  flex-shrink: 0;
	  background-color: $bg-100;
	  height: 3rem;
	  width: 3.5rem;
	  border-radius: 0.4375rem;
	  border: 0;
	  outline: none;
	  color: $base-400;
	  text-align: left;
	  padding-left: 0.5rem;
	  font-family: "Stolzl", sans-serif;
	  font-weight: 300;
	  background-image: url("../../assets/images/select-arrow.svg");
	  background-repeat: no-repeat;
	  background-size: 30px 9px;
	  background-position: center right;
	  -webkit-appearance: none;

	  @include breakpoint(xl){
		order: 1;
		margin:0 0 0 auto;
	  }
	}

	&_button{
	  margin-bottom: 2rem;
	  flex-shrink: 0;
	  height: 3.375rem;

	  @include breakpoint(xl){
		order: 2;
		margin:0 0 0 1.5rem;
	  }
	}

	&_contacts-mobile{
	  margin-top: auto;
	  display: flex;
	  flex-direction: column;
	  gap: 2rem;

	  @include breakpoint(xl){
		display: none;
	  }

	  &_title{
		margin-bottom: 0.25rem;
		color: $base-300;
	  }
	}
  }
}

.navbar_toggler{
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;

  @include breakpoint(xl){
    display: none;
  }

}