.project-gallery{
  padding: 1.25rem 0 0;

  @include breakpoint(md){
	padding: 4.19rem 0 4rem;
  }

  @include breakpoint(xxl){
	padding: 4.37rem 0 5.44rem;
  }

  &__wrapper{

	@include breakpoint(md){
	  display: grid;
	  align-items: stretch;
	  width: 100%;
	  grid-template-columns: 1fr 1fr;
	  gap: 1.25rem;
	  transform: none!important;
	  transition: none!important;

	  .swiper-slide{
		width: 100%!important;
	  }
  	}
  }

  &__swiper_nav{
	margin-top: 1.25rem!important;

	@include breakpoint(md){
	  display: none!important;
	}
  }

  &__slide{
	a{
	  border-radius: 1rem;
	  display: block;
	  width: 100%;
	  overflow: hidden;
	  transition: 0.5s all ease;

	  &:hover{
		img{
		  transform: scale(1.1);
		}
	  }
	}

	img{
	  display: block;
	  width: 100%;
	  transition: 0.5s all ease;
	}
  }
}