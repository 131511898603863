body{
  .swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
	background: rgba(34, 34, 34, 0.50);
  }

  .swal2-popup{
	background: $white;
	width: 45.8125rem;
	border-radius: 1rem;
	font-family: "Stolzl", Arial, sans-serif;

	@include breakpoint(xxl){
	  border-radius: 1.5rem;
	}
  }

  .swal2-close{
	color: $base-400;
  }

  .swal_inner{
	max-width: 30.6875rem;
	margin: 0 auto;
	color: $base-400;
	text-align: left;
	padding: 4.38rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.title{
	  text-align: center;
	  margin-bottom: 2.38rem;

	  span{
		padding: 0.3rem 0.5rem;
	  }
	}
  }

  .swal2-content{
	padding: 0;
	font-size: 1rem;
  }
}