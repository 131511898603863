.form{
  &__fildset{
	margin-bottom: 0.75rem;
  }

  &__input{
	display: block;
	width: 100%;
	border-radius: 3.3125rem;
	border: 1px solid $bg-100;
	color: $base-300;
	background-color: transparent;
	padding: 0 1.5rem;
	font-family: "DM Mono", sans-serif;
	height: 2.625rem;

	&::placeholder{
	  color: $accent-300;
	  font-family: "DM Mono", sans-serif;
	  font-size: 0.875rem;
	  font-style: normal;
	  font-weight: 400;
	  line-height: normal;
	}

	&:focus{
	  border-color: $accent-300;
	}
  }

  .button{
	margin-top: 2.94rem;
	width: 100%;
  }
}