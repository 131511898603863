.portfolio{
  padding: 5rem 0;
  background-color: $bg-100;

  @include breakpoint(xxl){
	padding: 10rem 0 9.19rem;
  }

  &__inner{
	display: flex;
	flex-direction: column;
	justify-content: center;
  }

  &__title{
	margin-bottom: 1.94rem;

	@include breakpoint(xl){
	  margin-bottom: 2.5rem;
	}

	@include breakpoint(xxl){
	  margin-bottom: 4.03rem;
	}
  }

  &__nav{
	display: flex;
	flex-wrap: wrap;
	gap: 0.25rem 0.2rem;
	margin-bottom: 1.53rem;

	@include breakpoint(lg){
	  gap: 0.5rem 0.75rem;
	}

	@include breakpoint(xl){
	  gap: 1rem 1.5rem;
	}

	li{
	  a{
		border-radius: 3.1875rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		padding: 0.5rem 0.5rem;
		transition: 0.5s all ease;
		color: $base-300;
		font-size: 0.875rem;
		//font-weight: 400;

		@include breakpoint(xl){
		  padding: 0.75rem 1.5rem;
		  font-size: 1rem;
		  gap: 0.75rem;
		}

		@include breakpoint(xxl){
		  font-size: 1.25rem;
		}
	  }

	  &.active a, a:hover{
		color: $base-400;
		background-color: $white;
	  }
	}
  }

  &__list{
	margin-bottom: 3.75rem;
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;

	@include breakpoint(md){
	  grid-template-columns: 1fr 1fr;
	  gap: 2.5rem 1.25rem;
	}

	@include breakpoint(xxl){
	  gap: 3rem 1.25rem;
	}
  }

  &__button{
	margin: 0 auto;
  }

  &__white{
	background-color: $white;

	.portfolio__nav li.active a,
	.portfolio__nav li a:hover{
	  background-color: $bg-100;
	}

	.tags-list{
	  li{
		a, p{
		  border-color: $accent-400;
		  background-color: $accent-100;
		  color: $accent-400;

		  &:hover{
			background-color: $accent-400;
			color: $white;
		  }
		}
	  }
	}
  }
}


.portfolio-case{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  &__hover_btn{
	width: 0;
	height: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50% -50%);
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	z-index: 1;
	transition-property: width, height;
	  transition-duration: .4s;
	  transition-timing-function: ease;

	&.active{
	  width: 4rem;
	  height: 4rem;
	  transition-property: width, height;
	  transition-duration: .4s;
	  transition-timing-function: ease;
	}

	&-inner{
	  display: flex;
	  width: 4rem;
	  height: 4rem;
	  justify-content: center;
	  align-items: center;
	  flex-shrink: 0;
	  background: url("../../assets/images/arrow-down.svg")no-repeat center $accent-400;
	  background-size: 1.5rem 1.5rem;
	  border-radius: 50%;
	  transition: 0.5s all ease;
	}
  }

  &__img{
	border-radius: 1rem;
	display: block;
	margin-bottom: 2rem;
	overflow: hidden;
	transition: 0.5s all ease;
	position: relative;

	&:hover{
	  .portfolio-case__hover_btn{

	  }

	  img{
		transform: scale(1.1);
	  }
	}

	img{
	  display: block;
	  width: 100%;
	  transition: 0.5s all ease;
	}
  }

  &__title{
	margin-bottom: 0.5rem;
	transition: 0.5s all ease;
	color: $accent-400;

	&:hover{
	  color: $accent-300;
	}
  }

  &__description{
	margin-bottom: 2rem;
  }
}

.portfolio-count{
  position: relative;
  font-family: "DM Mono", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  color: $base-300;
  padding: 0 0.5rem;
  text-align: center;
  display: inline-block;

  @include breakpoint(xl){
	font-size: 0.875rem;
	padding: 0 0.75rem;
  }

  &:before, &:after{
	content: '';
	width: 0.2rem;
	height: 0.75rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background: url("../images/break.svg")no-repeat;
	background-size: 100% 100%;

	@include breakpoint(xl){
	  width: 0.3125rem;
	  height: 1.25rem;
	}
  }

  &:before{
	left: 0;
  }

  &:after{
	right: 0;
	transform: translateY(-50%) rotate(180deg);
  }
}