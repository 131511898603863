.swiper-button-prev,
.swiper-button-next {
  color: $white;
  background-color: $accent-400;
  flex-shrink: 0;
  width: 4rem;
  height: 3rem;
  border-radius: 0.5rem;
  opacity: 1;
  border: 0;
  transition: 0.5s all ease;

  &:after{
    content: '';
    background: url("../../assets/images/arrow-down.svg")no-repeat;
    background-size: 100% 100%;
    width: 24px;
    height: 24px;
  }

  &:hover{
    opacity: 0.8;
  }
}

.swiper-button-prev{
  &:after{
    transform: rotate(180deg);
  }
}

.swiper-pagination{
  &-bullet{
    background: $accent-100;
    width: 0.875rem;
    height: 0.875rem;
	opacity: 1;

    &-active{
      background: $accent-400;
    }
  }
}

.swiper__nav{
  display: flex;
  align-items: center;
  gap: 0.75rem;

  &--center{
    margin: 2.69rem auto 0;
    justify-content: center;
  }

  &--left{
    justify-content: flex-start;
  }

  &--right{
    justify-content: flex-end;
  }

  .swiper-button-prev,
  .swiper-button-next{
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    margin-top: 0;
  }

  .swiper-pagination{
    position: relative;
    bottom: auto;
    left: auto;
    top: auto;
    right: auto;
    width: auto;
  }
}

.swiper-slide{
  display: flex;
  height: auto;
}

