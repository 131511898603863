.services{
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //min-height: 100vh;
  overflow: hidden;

  @include breakpoint(xl){
	padding: 11.25rem 0;
  }

  @include breakpoint(xxl){
	padding: 15.94rem 0 11.25rem;
  }

  &__inner{
	display: flex;
	flex-direction: column;

	@include breakpoint(xl){
	  display: grid;
	  grid-template-columns: 489px 1fr;
	  grid-template-rows: auto 1fr;
	  grid-template-areas: 'services-title services-slider' 'services-slider-nav services-slider';
	  gap:1rem 1.5rem;
	}

	@include breakpoint(xxl){
	  grid-template-columns: 556px 1fr;
	}
  }

  &__title{
	grid-area: services-title;
  }

  &__title{
	grid-area: services-title;
	margin-bottom: 3rem;

	@include breakpoint(xl){
	  margin-bottom: 0;
	}
  }

  &__swiper_wrapper{
	grid-area: services-slider;
  }

  &__swiper_nav{
	grid-area: services-slider-nav;

	@include breakpoint(xl){
	  align-items: flex-start!important;
	  justify-content: flex-start!important;
	  width: 100%;
	}
  }

  &__swiper{
	overflow: initial;

	@include breakpoint(xl){
	  max-width: 1200px;
	  overflow: hidden;
	}

	@include breakpoint(xxl){
	  max-width: 1250px;
	}
  }

  &-card{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
	background-color: $bg-100;
	border-radius: 1rem;
	padding: 1.5rem 1rem 1rem 1rem;
	color: $base-300;
	min-height: 34.9375rem;
	text-align: center;
	transition: 0.5s all ease;

	@include breakpoint(xs){
	  padding: 1.5rem 1.25rem 1.25rem 1.25rem;
	}

	@include breakpoint(md){
	  &:hover{
		.services-card__button{
		  opacity: 1;
		}
	  }
	}

	@include breakpoint(xxl){
	  min-height: 37.4375rem;
	  padding: 3.5rem 1.5rem;
	}

	&__img{
	  display: block;
	  max-width: 4.25rem;
	  margin: 0 auto 2.44rem;
	}

	&__title{
	  color: $accent-400;
	  font-size: 2rem!important;
	}

	&__description{
	  margin-top: auto;
	}

	&__button{
	  margin: auto;
	  display: flex;
	  width: 4rem;
	  height: 4rem;
	  justify-content: center;
	  align-items: center;
	  flex-shrink: 0;
	  background-color: $accent-400;
	  border-radius: 50%;
	  transition: 0.5s all ease;
	  position: relative;

	  @include breakpoint(md){
		opacity: 0;
	  }

	  &:after{
		content: '';
		background: url("../../assets/images/arrow-down.svg")no-repeat;
		background-size: 100% 100%;
		width: 1.5rem;
		height: 1.5rem;
	  }

	}
  }
}