@font-face {
    font-family: 'Stolzl';
    src: url('Stolzl-Light.eot');
    src: local('Stolzl Light'), local('Stolzl-Light'),
        url('Stolzl-Light.eot?#iefix') format('embedded-opentype'),
        url('Stolzl-Light.woff2') format('woff2'),
        url('Stolzl-Light.woff') format('woff'),
        url('Stolzl-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stolzl';
    src: url('Stolzl-Thin.eot');
    src: local('Stolzl Thin'), local('Stolzl-Thin'),
        url('Stolzl-Thin.eot?#iefix') format('embedded-opentype'),
        url('Stolzl-Thin.woff2') format('woff2'),
        url('Stolzl-Thin.woff') format('woff'),
        url('Stolzl-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stolzl Book';
    src: url('Stolzl-Book.eot');
    src: local('Stolzl Book'), local('Stolzl-Book'),
        url('Stolzl-Book.eot?#iefix') format('embedded-opentype'),
        url('Stolzl-Book.woff2') format('woff2'),
        url('Stolzl-Book.woff') format('woff'),
        url('Stolzl-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stolzl';
    src: url('Stolzl-Regular.eot');
    src: local('Stolzl Regular'), local('Stolzl-Regular'),
        url('Stolzl-Regular.eot?#iefix') format('embedded-opentype'),
        url('Stolzl-Regular.woff2') format('woff2'),
        url('Stolzl-Regular.woff') format('woff'),
        url('Stolzl-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stolzl';
    src: url('Stolzl-Bold.eot');
    src: local('Stolzl Bold'), local('Stolzl-Bold'),
        url('Stolzl-Bold.eot?#iefix') format('embedded-opentype'),
        url('Stolzl-Bold.woff2') format('woff2'),
        url('Stolzl-Bold.woff') format('woff'),
        url('Stolzl-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stolzl';
    src: url('Stolzl-Medium.eot');
    src: local('Stolzl Medium'), local('Stolzl-Medium'),
        url('Stolzl-Medium.eot?#iefix') format('embedded-opentype'),
        url('Stolzl-Medium.woff2') format('woff2'),
        url('Stolzl-Medium.woff') format('woff'),
        url('Stolzl-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

