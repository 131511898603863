body{
  font-family: 'Stolzl', Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  background-color: $white;
  color:$base-400;
  font-size: 1rem;

  @include breakpoint(xxl){
    font-size: 1.125rem;
  }
}

a{
  color: $base-400;
  display: inline-block;
  text-decoration: none;
  line-height: 1.5;
  &:hover{
    color: $base-400;
  }
}

p{
  word-break: break-word;
  line-height: 1.55;
  letter-spacing: -0.02rem;

  @include breakpoint(xxl){
	letter-spacing: -0.0225rem;
  }

}

b, strong {
  font-weight: bold;
}

.container{
  max-width: 84.75rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;

  @include breakpoint(xxl){
    max-width: 108.5rem;
  }
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}


.accent_color-400{
  color: $accent-400;
}

.accent_color-300{
  color: $accent-300;
}

.base_color-300{
  color: $base-300;
}

.text-center{
  text-align: center;
}