.button{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  border-radius: 3.3125rem;
  height: 3.375rem;
  color: $white!important;
  background-color: $accent-400;
  text-align: center;
  font-weight: 300;
  font-size: 0.875rem;
  outline: none;
  border: 1px solid $accent-400;
  cursor: pointer;
  transition: 0.5s all ease;
  max-width: 100%;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  position: relative;

  @include breakpoint(xxl){
    height: 3.875rem;
	font-size: 1rem;
  }

  &:hover{
    opacity: 0.8;
  }

  &__icon{
	display: flex;
	flex-shrink: 0;
	width: 1.875rem;
	height: 1.875rem;
	justify-content: center;
	align-items: center;
	background-color: $white;
	border-radius: 50%;

	&_svg{
	  width: 1rem;
	  height: 1rem;
	}
  }

  &--light{
	background-color: $bg-100;
	border-color: $bg-100;
	color: $base-400!important;

	.button__inner{
	  background-color: $accent-400;
	  color: $white;
	}

	.button__icon{
	  background-color: $accent-400;
	}
  }

  &--animate{
	padding: 0.5rem 3.875rem 0.5rem 1.5rem;
	justify-content: space-between;

	&:hover{
	  opacity: 1;

	  .button__inner{
		clip-path: circle(150% at calc(100% - 1.4rem) 50%);
	  }

	  .button__icon_svg{
		animation: rotate 2s ease infinite;
	  }
	}
  }

  &__inner{
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1.5rem;
	border-radius: 3.3125rem;
	padding: 0.5rem 0.5rem 0.5rem 1.5rem;
	position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    white-space: nowrap;
    transition-property: all;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    clip-path: circle(1rem at calc(100% - 1.4rem) 50%);
	overflow: hidden;
	background-color: $white;
	color: $accent-400;
  }
}

@keyframes rotate {
  0% {
	  transform: rotate(0deg);
  }
  50% {
	  transform: rotate(1turn);
  }
  50.001% {
	  transform: rotate(1turn);
  }
  100% {
	  transform: rotate(1turn);
  }
}
