$accent-400: #3D4891;
$accent-300: #9BA1C9;
$accent-100: #E9EBF8;

$base-400: #2D2D2D;
$base-300: #6D6F80;

$bg-100: #F3F3F3;

$black: #000;
$white: #fff;
