.section{
  &__heading{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 3.81rem;
	gap: 4.19rem;

	@include breakpoint(md){
	  display: grid;
	  grid-template-columns: 1fr 1.5fr;
	  gap: 1.5rem;
	  margin-bottom: 5rem;
	}

	@include breakpoint(xxl){
	  grid-template-columns: 1.25fr 1fr;
	  margin-bottom: 9.25rem;
	}
  }
}