.project-heading{
  padding: 2.5rem 0 5rem;

  @include breakpoint(xl){
	padding: 5rem 0 9rem;
  }

  &__container{
	display: flex;
	flex-direction: column;
	gap: 2.5rem;

	@include breakpoint(lg){
	  gap: 5rem;
	}
  }

  &__title-wrap{
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
	width: 100%;

	@include breakpoint(lg){
	  flex-direction: row;
	  align-items: center;
	  justify-content: space-between;
	}
  }

  &__title{
	flex-grow: 1;
	width: 100%;

	.title span{
	  @media (max-width: 767px){
		background-color: transparent;
		white-space: normal;
	  }
	}
  }

  &__tags{

	@include breakpoint(lg){
	  margin: auto;
	  justify-content: flex-end;
	}
  }

  &__banner{
	display: block;
	width: 100%;
	border-radius: 1rem;
  }

  &__grid{
	font-weight: 400;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-areas: 'project-heading__title project-heading__date'
						 'project-heading__description project-heading__description';
	gap: 1.81rem;

	@include breakpoint(md){
	  display: block;
	}

	&_title{
	  grid-area: project-heading__title;

	  @include breakpoint(md){
		margin-bottom: 3rem;
	  }
	}

	&_date{
	  grid-area: project-heading__date;

	  @include breakpoint(md){
		float: left;
		width: 35%;
		min-height: 12rem;
		margin-right: 2.5rem;
	  }

	  @include breakpoint(xxl){
		min-height: 15rem;
	  }
	}

	&_description{
	  grid-area: project-heading__description;
	  font-size: 1.2rem;

	  @include breakpoint(md){
		font-size: 1.5rem;
	  }

	  @include breakpoint(xxl){
		font-size: 2rem;
	  }
	}
  }

  &__about{
	font-weight: 400;
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.25rem;

	@include breakpoint(md){
	  grid-template-columns: 35% 1fr;
	  gap: 2.5rem;
	}

	&_title{
	  margin-bottom: 2.5rem;
	}

	&_description{

	  @include breakpoint(md){
		max-width:23.3125rem;
	  }
	}
  }

  &__button{
	align-self: flex-start;

	@include breakpoint(lg){
	  margin: 0 auto;
	}
  }
}