.hero{
  padding: 6.81rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 72px);

  @include breakpoint(xl){
	min-height: calc(100vh - 144px);
  }

  &__inner{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
  }

  &__subtitle{
	margin-bottom: 3.5rem;

	@include breakpoint(xl){
	  align-self: flex-end;
	  margin-bottom: -1.75rem;
	}
  }

  &__title{
	display: flex;
	flex-direction: column;
	width: 100%;
	white-space: nowrap;

	&_p{
	  line-height: 1;
	  align-self: flex-end;

	  //@include breakpoint(xl){
		//margin-left: 20rem;
		//align-self: flex-start;
	  //}

	  @include breakpoint(xl){
		line-height: 0.95;
	  }
	}

	&_span{
	  align-self: flex-start;
	  padding: 0.6rem!important;

	  @include breakpoint(xl){
		margin-bottom: 0.8rem;
		line-height: 0.95;
	  }
	}
  }

  &__description{
	max-width: 19.8125rem;
	color: $base-300;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.69rem;
	margin-top: 3.5rem;

	@include breakpoint(sm){
	  margin-top: 1rem;
	}

	@include breakpoint(xl){
	  margin-top: -10rem;
	}

	@include breakpoint(xl){
	  margin-top: -15.5rem;
	  gap: 4.87rem;
	}

	@include breakpoint(xxl){
	  margin-top: -18.5rem;
	  gap: 4.94rem;
	}
  }
}