.why-us{
  padding: 6.81rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //min-height: 100vh;

  @include breakpoint(xl){
  	padding: 9.25rem 0;
  }

  &__list{
	display: flex;
	flex-direction: column;
	gap: 4.44rem;

	@include breakpoint(sm){
	  gap: 1rem;
	  max-width: 30rem;
	  margin: 0 auto;
	}

	@include breakpoint(lg){
	  max-width: 100%;
	  flex-direction: row;
	  justify-content: space-between;
	}

	&_item{
	  display: flex;
	  flex-direction: column;
	  align-items: flex-start;

	  &:nth-child(even) {
		align-items: flex-end;

		@include breakpoint(lg){
		  align-items: flex-start;
		  margin-top: 15rem;
		}

		@include breakpoint(xl){
		  margin-top: 11rem;
		}
	  }

	  &-inner{
		display: flex;
	    flex-direction: column;
	  }

	  &-title{
		font-size: 7.5rem;
		color: $accent-400;
		white-space: nowrap;

		@include breakpoint(xs){
		  font-size: 9rem;
		  letter-spacing: -0.28125rem;
		}
	  }

	  &-description{
		background-color: $white;
		padding: 1rem 0 0 1rem;
		color: $base-300;
		margin: -3.25rem 0 0 3.25rem;
		align-self: flex-end;
		max-width: 15rem;

		@include breakpoint(xxl){
		  margin-top: -2.5rem;
		}
	  }
	}
  }
}