.project-steps{
  background-color: $bg-100;
  padding: 5rem 0;

  @include breakpoint(lg){
	padding: 6.25rem 0;
  }

  &__inner{
	display: grid;
	grid-template-columns: 1fr;
	gap: 2.5rem;

	@include breakpoint(lg){
	  grid-template-columns: 1fr 1fr;
	  gap: 1.25rem;
	}
  }

  &__list{
	display: flex;
	flex-direction: column;
	gap: 1.25rem;

    @for $i from 1 through 8 {
      .project-steps__item:nth-child(#{$i}n) {
        top: $i * 1.25rem;
      }
    }
  }

  &__item{
	position: sticky;
	border-radius: 1rem;
	border: 1px solid $base-300;
	background: $white;
	display: flex;
	min-height: 24.6875rem;
	padding: 1.875rem;
	flex-direction: column;
	gap: 1.5rem;
	width: 100%;

	@include breakpoint(lg){
	  min-height: 20.9375rem;
	}

	&_index{
	  letter-spacing: -0.03rem;
	  color: $base-300;
	  font-weight: 400;
	}

	&_title{
	  margin-top: auto;
	  color: $accent-400;
	}
  }

  .tags-list{
	margin-top: 0;
  }

}