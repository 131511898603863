h1, .h1{
  font-size: 3rem;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.11625rem;

  @include breakpoint(xs){
	font-size: 3.5rem;
  }

  @include breakpoint(sm){
	font-size: 5rem;
  }

  @include breakpoint(md){
	font-size: 6.5rem;
  }

  @include breakpoint(lg){
	font-size: 8rem;
  }

  @include breakpoint(xl){
	font-size: 9.375rem;
	letter-spacing: -0.28125rem;
  }

  @include breakpoint(xxl){
	font-size: 11.25rem!important;
	line-height: 0.88;
	letter-spacing: -0.3375rem;
  }
}

h2, .h2{
  font-size: 2.75rem;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.11625rem;

  @include breakpoint(xs){
	font-size: 3.2rem;
  }

  @include breakpoint(xl){
	font-size: 5.5rem;
	letter-spacing: -0.165rem;
  }

  @include breakpoint(xxl){
	font-size: 6.125rem;
	letter-spacing: -0.18375rem;
  }

  &.smaller{
	font-size: 2.75rem;

	@include breakpoint(xl){
	  font-size: 4.5rem;
	}
  }
}

h3, .h3{
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.08rem;
  text-transform: uppercase;

  @include breakpoint(xl){
	font-size: 3rem;
	letter-spacing: -0.12rem;
  }

  @include breakpoint(xxl){
	font-size: 4rem;
	letter-spacing: -0.16rem;
  }
}

h4, .h4{
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: -0.04rem;
  text-transform: uppercase;
}

h5, .h5{
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.03rem;
}

h6, .h6{
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  font-style: normal;

  @include breakpoint(md){
    font-size:1.1rem;
  }

  @include breakpoint(xxl){
    font-size:1.25rem;
    line-height: 1.3;
  }
}

.title{
  span{
	color: $accent-400;
	background-color: $accent-100;
	border-radius: 1rem;
	padding: 0.4rem 0.5rem;
	white-space: nowrap;
    position: relative;
    z-index: -1;

	@include breakpoint(sm){
	  padding: 0.5rem 1rem;
	}

	@include breakpoint(xl){
	  padding: 0.75rem 1.25rem;
	}

	@include breakpoint(xxl){
	  padding: 1rem 1.37rem;
	}
  }
}

.subtitle{
  position: relative;
  font-family: "DM Mono", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  color: $base-300;
  padding: 0 2rem;
  text-align: center;
  display: inline-block;

  @include breakpoint(xxl){
	font-size: 0.875rem;
  }

  &:before, &:after{
	content: '';
	width: 0.3125rem;
	height: 1.25rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background: url("../images/break.svg")no-repeat;
	background-size: 100% 100%;

	@include breakpoint(xxl){
	  width: 0.5625rem;
	  height: 2.25rem;
	}
  }

  &:before{
	left: 0;
  }

  &:after{
	right: 0;
	transform: translateY(-50%) rotate(180deg);
  }
}