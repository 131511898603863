.footer{
  margin-top: auto;
  background-color: $accent-400;
  color: $white;
  overflow: hidden;
  padding-top: 4.88rem;
  font-size: 0.875rem;

  @include breakpoint(xxl){
	font-size: 1rem;
  }

  &__caption{
	max-width: 540px;
	margin: 0 auto;

	@include breakpoint(md){
	  max-width: 100%;
	}
  }

  &__list{
	min-width: 130px;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	li{
	  a{
		transition: 0.5s all ease;
		line-height: normal;

		&:hover{
		  color: $accent-300;
		}
	  }
	}
  }

  a{
	color: $white;
  }

  &__mailing{
	width: 100%;

	@include breakpoint(md){
	  width: 50%;
	  order: 3;
	  max-width: 25rem;
	}

	@include breakpoint(xl){
	  max-width: 30rem;
	}

	@include breakpoint(xxl){
	  max-width: 44rem;
	}

	&_title{
	  font-size: 1.5rem;
	  font-weight: 400;
	  line-height: 1.3;
	  letter-spacing: -0.03rem;
	  margin-bottom: 1.31rem;

	  @include breakpoint(md){
		margin-bottom: 1.5rem;
	  }
	}

	&_form{
	  display: flex;
	  gap: 0.5rem;
	  align-items: flex-start;

	  @include breakpoint(xxl){
		gap: 0.69rem;
	  }
	}

	.form__input{
	  color: $white;

	  @include breakpoint(xxl){
		height: 3.125rem;
	  }
	}

	&_input_wrap{
	  position: relative;
	  width: 100%;
	}

	&_button{
	  display: flex;
	  width: 2.625rem;
	  height: 2.625rem;
	  flex-direction: column;
	  justify-content: center;
	  align-items: center;
	  flex-shrink: 0;
	  background-color: $white;
	  border-radius: 50%;
	  outline: none;
	  border: 0;
	  transition: 0.5s all ease;

	  @include breakpoint(xxl){
		width: 3.125rem;
	  	height: 3.125rem;
	  }

	  &:hover{
		background-color: $accent-100;
	  }

	  svg{
		width: 1.125rem;
		height: 1.125rem;
	  }
	}
  }

  &__nav{
	@include breakpoint(md){
	  order: 0;
	}
  }

  &__social{
	@include breakpoint(md){
	  order: 1;
	}
  }

  &__top{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 3.94rem 0;
	margin-bottom: 13.25rem;

	@include breakpoint(md){
	  margin-bottom: 9.31rem;
	}

	@include breakpoint(xxl){
	  margin-bottom: 20rem;
	}
  }

  &__bottom{
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 4.63rem 1rem;
	padding-bottom: 0.81rem;
	border-bottom: 1px solid $accent-300;
	position: relative;
	z-index: 1;

	@include breakpoint(md){
	  display: flex;
	  justify-content: space-between;
	}

	&_col{
	  display: flex;
	  flex-direction: column;
	  justify-content: flex-end;
	  gap: 0.56rem;
	}

	a{
	  transition: 0.5s all ease;

	  &:hover{
		color: $accent-300;
	  }
	}
  }

  &__privacy-policy{
	font-size: 0.75rem;

	@include breakpoint(md){
	  span{
		display: block;
	  }
	}
  }


  &__copyright{
	font-size: 0.75rem;
	align-self: flex-end;
  }

  &__logo{
	font-size: 5rem;
	line-height: 6.25rem;
	letter-spacing: -0.18563rem;
	margin: 0.5rem auto -2.12rem;
	text-align: center;
	white-space: nowrap;
	position: relative;
	font-weight: 400;

	@include breakpoint(xs){
	  font-size: 6.1875rem;
	}

	@include breakpoint(sm){
	  font-size: 9rem;
	  margin: 0.5rem auto -2rem;
	}

	@include breakpoint(md){
	  font-size: 13rem;
	  margin: 0.5rem auto -3rem;
	  line-height: 0.8;
	}

	@include breakpoint(lg){
	  font-size: 17rem;
	  margin: 0.5rem auto -4rem;
	}

	@include breakpoint(xl){
	  font-size: 22.75rem;
	  margin: 0.5rem auto -5.5rem;
	  letter-spacing: -0.6825rem;
	}

	@include breakpoint(xxl){
	  font-size: 29.16275rem;
	  letter-spacing: -0.87488rem;
	  margin: 0.5rem auto -7.5rem;
	}

	span{
	  position: relative;
	  display: block;
	  left: 50%;
	  transform: translateX(-50%);
	  width: fit-content;
	}
  }
}

